<template>
  <div class="form form-col-1">
    <div class="form-content text-center">
      <div class="header-images">
        <div class="logo">
          <img
            src="/images/logos/logo-faber.png"
            alt="Faber Castell"
            title="Faber Castell"
          />
        </div>
        <div class="featured">
          <img
            src="/images/decorations/email-success.png"
            alt="Email éxitoso"
            title="Email éxitoso"
          />
        </div>
      </div>
      <div class="body">
        <h1 class="mb-1 font_helvetica_neue75_bold">
          Confirmación de correo electrónico
        </h1>
        <p class="font_helvetica_neue55_roman">
          Revisa el email que te hemos enviado para completar con éxito tu
          registro
        </p>
      </div>
      <div class="buttons">
        <span class="btn-primary-outline-static pl-1 pr-1"
          >Si ya confirmaste tu correo electrónico, ya puedes cerrar esta
          ventana.</span
        >
      </div>
      <LoadingComponent v-if="isLoading" />

      <MessageComponent
        v-if="!isLoading && messageForm.message"
        :message="messageForm.message"
        :messageType="messageForm.messageType"
        :classes="['text-center', 'mt-1', 'form-message']"
      />
      <div class="footer">
        <p class="font_helvetica_neue55_roman">
          ¿Aún no recibiste nuestro email? Revista tu carpeta de spam o
          <a @click="createPassword" class="link pointer">Vuelve a enviarlo</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount } from 'vue';
import { LoadingComponent, MessageComponent } from '@/components';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';
import useForgotPassword from '@/modules/auth/composables/forgot-password/useForgotPassword';

export default {
  components: {
    LoadingComponent,
    MessageComponent,
  },
  setup() {
    const { createPassword, isLoading, messageForm } = useForgotPassword();
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('email-confirmation');
    });
    return {
      isLoading,
      messageForm,
      createPassword,
    };
  },
};
</script>
